import { AppShell, Burger, Container, Group } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { matchRoutes, Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import HeaderUserButton from './HeaderNavbar/HeaderUserButton';

const routes = [{ path: '/groups/:id' }];

export function Layout() {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  const [opened, { toggle }] = useDisclosure();
  const isMobile = useMediaQuery('(max-width: 750px)');

  const isGroupDetailsPage = matches && matches[0].route.path === '/groups/:id';

  const headerHeight = isMobile ? 60 : 0;
  const paddingTop = isMobile ? 80 : 40;

  return (
    <AppShell
      transitionDuration={400}
      transitionTimingFunction="ease"
      layout="alt"
      header={{ height: headerHeight }}
      navbar={{ width: 280, breakpoint: 'sm', collapsed: { mobile: !opened } }}
    >
      <AppShell.Header>
        <Group h="100%" px="md" justify="space-between" hiddenFrom="sm">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" />

          <HeaderUserButton />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar withBorder={false} px="md">
        <Group>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" pt={30} />
        </Group>

        <Sidebar toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main
        style={{ backgroundColor: '#FAF9F8', paddingTop: isGroupDetailsPage ? 0 : paddingTop }}
      >
        {isGroupDetailsPage ? (
          <Outlet />
        ) : (
          <Container fluid px={{ base: 'md', sm: 'lg', md: 'xl' }} pb={40}>
            <Outlet />
          </Container>
        )}
      </AppShell.Main>
    </AppShell>
  );
}
