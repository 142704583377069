import { rem, Tabs } from '@mantine/core';
import { IconCalendarClock, IconUser } from '@tabler/icons-react';
import React, { useState } from 'react';

enum TabList {
  PEOPLE = 'people',
  TRAININGS = 'trainings',
}

export default function GroupDetails() {
  const [activeTab, setActiveTab] = useState(TabList.PEOPLE);

  const iconStyle = { width: rem(15), height: rem(15) };

  return (
    <div>
      <Tabs
        keepMounted={false}
        value={activeTab}
        onChange={(val) => setActiveTab(val as TabList)}
        variant="outline"
      >
        <Tabs.List>
          <Tabs.Tab value={TabList.PEOPLE} leftSection={<IconUser style={iconStyle} />}>
            Članovi
          </Tabs.Tab>
          <Tabs.Tab value={TabList.TRAININGS} leftSection={<IconCalendarClock style={iconStyle} />}>
            Treninzi
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TabList.PEOPLE}>Gallery tab content</Tabs.Panel>

        <Tabs.Panel value={TabList.TRAININGS}>Messages tab content</Tabs.Panel>
      </Tabs>
    </div>
  );
}
