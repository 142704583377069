import {
  ActionIcon,
  Anchor,
  Badge,
  Menu,
  NumberFormatter,
  Table,
  Text,
  Title,
  rem,
} from '@mantine/core';
import dayjs from 'dayjs';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Membership } from '@/models/Membership';
import NoDataTable from '../Common/Table/NoDataTable';
import IsGranted from '../Common/IsGranted';
import useShowModal from '../Modals/showModal';
import DeletePaidMembership from '@/containers/Memberships/DeletePaidMembership';
import { Permission } from '@/models/Permission';

export interface PaidMembershipsTableProps {
  data: Membership[];
  currency: string;
}

export default function PaidMembershipsTable({ data, currency }: PaidMembershipsTableProps) {
  const showModal = useShowModal();
  const navigate = useNavigate();

  const deleteMembership = (membership: Membership) =>
    showModal({
      id: 'delete-membership-payment',
      title: 'Izbrisi clanarinu',
      children: <DeletePaidMembership membership={membership} />,
      size: 'md',
    });

  const handleRowsRender = () => {
    if (data.length === 0) {
      return <NoDataTable text="😔 Nažalost nema plaćenih članarina.." />;
    }

    return data.map((membership) => (
      <Table.Tr key={membership.id}>
        <Table.Td
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/members/${membership.member.id}`)}
        >
          <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
            {membership.member.full_name}
          </Anchor>
        </Table.Td>

        <Table.Td>
          <Badge size="lg">
            <NumberFormatter
              suffix={` ${currency}`}
              value={membership.amount_paid}
              thousandSeparator
            />
          </Badge>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {dayjs(membership.month).format('YYYY/MMMM')}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {membership.collected_by.full_name}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {dayjs(membership.created_at).format('dddd, MMMM D, YYYY')}
          </Text>
        </Table.Td>

        <IsGranted permissions={Permission.MEMBERSHIP_MODIFY_VIEW}>
          <Table.Td style={{ cursor: 'pointer' }}>
            <Menu position="top" offset={10}>
              <Menu.Target>
                <ActionIcon radius="xl" variant="subtle" color="teal">
                  <IconDotsVertical size={20} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown px={10} pr={20} py={10}>
                <Menu.Label>Opcije</Menu.Label>
                <Menu.Divider />
                <Menu.Item
                  onClick={() => deleteMembership(membership)}
                  color="red"
                  leftSection={<IconTrash style={{ width: rem(20), height: rem(20) }} />}
                >
                  Izbriši članarinu
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Table.Td>
        </IsGranted>
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead style={{ width: '100%', backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th style={{ width: '30%' }}>
            <Title fw={400} size={16} c="gray.7">
              Polaznik
            </Title>
          </Table.Th>

          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Iznos
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Mesec
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Naplatio
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Naplaćeno datuma
            </Title>
          </Table.Th>

          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
}
