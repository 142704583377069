import dayjs from 'dayjs';
import { Badge, NumberFormatter, Table, Text, Title } from '@mantine/core';
import NoDataTable from '@/components/Common/Table/NoDataTable';
import { Membership } from '@/models/Membership';

export function MemberMembershipsTable({
  memberships,
  currency,
}: {
  memberships: Membership[];
  currency: string;
}) {
  const handleRowsRender = () => {
    if (memberships.length === 0) return <NoDataTable />;

    return memberships.map((membership, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Text c="gray.7" fw={500} size="md" ff="heading">
            {dayjs(membership.month).format('YYYY/MMMM')}
          </Text>
        </Table.Td>

        <Table.Td>
          <PaidStatus isPaid={membership.is_paid} shouldPay={membership.should_pay} />
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={500} size="md">
            {membership.is_paid && membership.amount_paid ? (
              <NumberFormatter
                suffix={` ${currency}`}
                value={membership.amount_paid}
                thousandSeparator
              />
            ) : (
              '-'
            )}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {membership.collected_by ? membership.collected_by.full_name : '-'}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {membership.created_at
              ? dayjs(membership.created_at).format('dddd, MMMM D, YYYY')
              : '-'}
          </Text>
        </Table.Td>

        <Table.Td />
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover mt={30}>
      <Table.Thead style={{ width: '100%', backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th style={{ width: '30%' }}>
            <Title fw={400} size={16} c="gray.7">
              Mesec
            </Title>
          </Table.Th>

          <Table.Th>
            <Title fw={400} fz="md" c="gray.7">
              Status
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} fz="md" c="gray.7">
              Iznos
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} fz="md" c="gray.7">
              Naplatio
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} fz="md" c="gray.7">
              Naplaćeno datuma
            </Title>
          </Table.Th>

          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
}

export const PaidStatus = ({ isPaid, shouldPay }: { isPaid: boolean; shouldPay: boolean }) => {
  if (!shouldPay) {
    return (
      <Badge color="yellow" size="md">
        nije trenirao/la
      </Badge>
    );
  }

  // Default behavior based on the `isPaid` prop
  return isPaid ? (
    <Badge color="teal" size="md">
      plaćeno
    </Badge>
  ) : (
    <Badge color="red" size="md">
      neplaćeno
    </Badge>
  );
};
