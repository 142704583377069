import { Anchor, Table, Text, Title } from '@mantine/core';
import { Group } from '@/models/Group';
import NoDataTable from '../Common/Table/NoDataTable';
import useShowModal from '../Modals/showModal';
import DeleteGroup from '@/containers/Groups/DeleteGroup';
import TableActionsMenu from '../Common/Table/TableActionMenu';

interface GroupsTableProps {
  data: Group[];
  onRowClick: () => void;
  setGroupDetails: React.Dispatch<React.SetStateAction<Group | undefined>>;
}

const GroupsTable = ({ data, onRowClick, setGroupDetails }: GroupsTableProps) => {
  const showModal = useShowModal();

  function handleRowClick(group: Group) {
    setGroupDetails(group);
    onRowClick();
  }

  const openDeleteModal = (groupId: string) => {
    showModal({
      id: 'delete-group',
      title: 'Izbriši grupu',
      children: <DeleteGroup groupId={groupId} />,
      size: 'md',
    });
  };

  const handleRowsRender = () => {
    if (data.length === 0) return <NoDataTable />;

    return data.map((group) => (
      <Table.Tr key={group.id}>
        <Table.Td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(group)}>
          <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
            {group.name}
          </Anchor>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {group.members.length}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {group.coaches.map((el) => el.full_name).join(', ')}
          </Text>
        </Table.Td>
        <Table.Td style={{ cursor: 'pointer' }}>
          <TableActionsMenu optionsHandler={() => openDeleteModal(group.id)} />
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover>
      <Table.Thead bg="#F6F8FA">
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th style={{ width: '30%' }}>
            <Title fw={400} size={16} c="gray.7">
              Ime grupe
            </Title>
          </Table.Th>

          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Broj članova
            </Title>
          </Table.Th>
          <Table.Th w="40%">
            <Title fw={400} size={16} c="gray.7">
              Treneri
            </Title>
          </Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
};

export default GroupsTable;
