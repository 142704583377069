import { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { difference } from 'lodash';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { useAddCoachesToGroup } from '@/api/hooks/groups/useAddCoachesToGroup';
import { useAddMembersToGroup } from '@/api/hooks/groups/useAddMembersToGroup';
import { Group } from '@/models/Group';
import { getUserFullName } from '@/models/User';
import { useEditGroupName } from '@/api/hooks/groups/useEditGroupName';
import { useRemoveCoachFromGroup } from '@/api/hooks/groups/useRemoveCoachFromGroup';
import { useRemoveMemberFromGroup } from '@/api/hooks/groups/useRemoveMemberFromGroup';
import { useAutoFetchMembers } from '@/api/hooks/members/useAutoFetchMembers';

type Inputs = {
  name: string;
};

interface EditGroupProps {
  group: Group;
  closeDrawer: () => void;
}

const EditGroup = ({ group, closeDrawer }: EditGroupProps) => {
  const { organisation } = useAccountStore();
  const { data: allCoaches, isLoading: isCoachesLoading } = useCoaches(organisation?.id as string);
  const { data: allMembers, isLoading: isMembersLoading } = useAutoFetchMembers(
    organisation?.id as string
  );

  const defaultCoachIds = group.coaches.map((el) => el.id);
  const defaultMemberIds = group.members.map((el) => el.id);

  const [selectedCoaches, setSelectecCoaches] = useState<string[]>(defaultCoachIds);
  const [selectedMembers, setSelectedMembers] = useState<string[]>(defaultMemberIds);

  const { mutateAsync: editGroupName, isPending: loadingNewGroupName } = useEditGroupName();
  const { mutateAsync: addCoachesToGroup, isPending: loadingCoaches } = useAddCoachesToGroup();
  const { mutateAsync: addMembersToGroup, isPending: loadingMembers } = useAddMembersToGroup();
  const { mutateAsync: deleteCoach, isPending: deletingCoaches } = useRemoveCoachFromGroup();
  const { mutateAsync: deleteMember, isPending: deletingMembers } = useRemoveMemberFromGroup();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const handleGroupUpdate: SubmitHandler<Inputs> = async (data) => {
    const newCoaches = difference(selectedCoaches, defaultCoachIds);
    const newMembers = difference(selectedMembers, defaultMemberIds);
    const removedCoaches = difference(defaultCoachIds, selectedCoaches);
    const removedMembers = difference(defaultMemberIds, selectedMembers);

    try {
      if (data.name.trim() !== group.name) {
        await editGroupName({ name: data.name, groupId: group.id });
      }

      if (removedCoaches.length > 0) {
        await Promise.all(
          removedCoaches.map((coachId) => deleteCoach({ groupId: group.id, coachId }))
        );
      }

      if (removedMembers.length > 0) {
        await Promise.all(
          removedMembers.map((memberId) => deleteMember({ groupId: group.id, memberId }))
        );
      }

      if (newCoaches.length > 0) {
        await addCoachesToGroup({ groupId: group.id, coacheIds: newCoaches });
      }

      if (newMembers.length > 0) {
        await addMembersToGroup({ groupId: group.id, memberIds: newMembers });
      }

      modals.closeAll();
      closeDrawer();
      notifications.showSuccessNotification({
        title: 'Uspesno',
        message: 'Uspesno ste izmenili grupu',
      });
    } catch (err) {
      console.error('Error while creating new group', err);
    }
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={
          loadingCoaches ||
          loadingMembers ||
          loadingNewGroupName ||
          deletingCoaches ||
          deletingMembers
        }
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Divider />
      <TextInput
        label="Ime Grupe"
        placeholder="Ime Grupe"
        error={errors.name?.message}
        type="text"
        w={{ base: '100%', sm: '45%' }}
        {...register('name', { required: 'Obavezno ime grupe', value: group.name })}
      />
      <Title style={{ fontSize: 18, color: 'gray' }}>
        Odaberite trenere i članove za vašu grupu:
      </Title>
      <form onSubmit={handleSubmit(handleGroupUpdate)}>
        <Paper>
          <Flex
            w="100%"
            justify="space-between"
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 20, sm: 0 }}
          >
            <Stack w={{ base: '100%', sm: '49%' }}>
              <MultiSelect
                searchable
                label="Treneri"
                placeholder={selectedCoaches.length > 0 ? '' : 'Treneri'}
                data={allCoaches?.results?.map((coach) => ({
                  value: coach.id,
                  label: getUserFullName(coach.user),
                }))}
                value={selectedCoaches}
                onChange={(value) => setSelectecCoaches(value)}
                nothingFoundMessage="Nema trenera za vasu pretragu..."
                disabled={isCoachesLoading}
                hidePickedOptions
              />
            </Stack>
            <Stack w={{ base: '100%', sm: '49%' }}>
              <MultiSelect
                searchable
                label="Clanovi"
                placeholder={selectedMembers.length > 0 ? '' : 'Clanovi'}
                data={allMembers.map((member) => ({
                  value: member.id,
                  label: member.full_name,
                }))}
                value={selectedMembers}
                onChange={(value) => setSelectedMembers(value)}
                nothingFoundMessage="Nema clanova za vasu pretragu..."
                disabled={isMembersLoading}
                hidePickedOptions
              />
            </Stack>
          </Flex>
        </Paper>

        <Flex gap={10} justify="flex-end" mt={15}>
          <Button variant="default" onClick={() => modals.closeAll()}>
            Odustani
          </Button>
          <Button type="submit" leftSection={<IconEdit size={18} />}>
            Izmeni
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export default EditGroup;
