import { Anchor, Table, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import NoDataTable from '../Common/Table/NoDataTable';
import { Member } from '@/models/Member';

interface UnpaidMembershipsTableProps {
  data?: Partial<Member>[];
}

export default function UnpaidMembershipsTable({ data }: UnpaidMembershipsTableProps) {
  const navigate = useNavigate();
  const handleRowsRender = () => {
    if (!data) return <NoDataTable text="🥳 Nema neplaćenih članarina za izabrani mesec." />;

    return data.map((member) => (
      <Table.Tr key={member.id}>
        <Table.Td
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => navigate(`/members/${member.id}`)}
        >
          <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
            {member.full_name}
          </Anchor>
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead style={{ width: '100%', backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th style={{ width: '100%' }}>
            <Title fw={400} size={16} c="gray.7">
              Polaznik
            </Title>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
}
