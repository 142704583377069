import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import PageLoading from '@/components/Common/PageLoading';
import useShowModal from '@/components/Modals/showModal';
import { Training } from '@/models/Training';
import EditTraining from '@/containers/Trainings/EditTraining';
import TrainingBasicInfo from '@/components/Trainings/Details/TrainingBasicInfo';
import { useTrainingDetails } from '@/api/hooks/trainings/useTrainingDetails';
import TrainingActionButtonMenu from '@/components/Trainings/Details/TrainingActionButtonMenu';
import DeleteTraining from '@/containers/Trainings/DeleteTraining';
import TrainingAttendance from '@/containers/Trainings/Details/TrainingAttendance';
import ResourceNotFound from '@/components/Common/ResourceNotFound/ResourceNotFound';
import { UserRole, hasRole } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';

export const TrainingDetails = () => {
  const { user } = useAccountStore();
  const showModal = useShowModal();
  const { id } = useParams();
  const { data: trainingDetails, isLoading, isError } = useTrainingDetails(id as string);

  /* Owner can trigger edit or delete whenever - Coaches can only trigger
  those actions 15 minutes from start datetime of trainig */
  const canTriggerTrainigActions =
    user && hasRole(user, UserRole.OWNER) ? true : isWithin15Minutes(trainingDetails?.date as Date);

  if (isLoading) return <PageLoading />;
  if (isError) return <ResourceNotFound />;

  function openEditTrainingModal(training: Training) {
    showModal({
      id: 'edit-training',
      title: 'Izmeni trening',
      children: <EditTraining training={training} />,
    });
  }

  function openDeleteTrainingModal() {
    showModal({
      id: 'delete-training',
      title: 'Izbriši trening',
      children: trainingDetails ? <DeleteTraining trainingId={trainingDetails?.id} /> : null,
      size: 'md',
    });
  }

  return (
    <div>
      <PageInfoHeader
        title={`Trening: ${trainingDetails?.topic || dayjs(trainingDetails?.date).format('DD/MM/YYYY HH:mm')}`}
        subtitle=""
        actionButton={
          <TrainingActionButtonMenu
            onTrainingEdit={() => openEditTrainingModal(trainingDetails as Training)}
            onTrainingDelete={openDeleteTrainingModal}
            editable={canTriggerTrainigActions}
          />
        }
      />

      {trainingDetails ? (
        <>
          <TrainingBasicInfo training={trainingDetails} />

          <TrainingAttendance trainingAttendances={trainingDetails.attendances} />
        </>
      ) : null}
    </div>
  );
};

export function isWithin15Minutes(trainingDate: Date) {
  const now = dayjs();
  const trainingFormattedDate = dayjs(trainingDate);
  const timeDifference = now.diff(trainingFormattedDate);
  const fifteenMinutesInMilliseconds = 15 * 60 * 1000;
  return timeDifference <= fifteenMinutesInMilliseconds;
}
