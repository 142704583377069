import { Card, Skeleton } from '@mantine/core';

export default function TableSkeletonLoading() {
  return (
    <Card padding="lg" style={{ marginTop: 0 }}>
      {Array.from({ length: 15 }).map((_, i) => (
        <Skeleton key={i} height={30} width="100%" mt={10} />
      ))}
    </Card>
  );
}
