import { Tabs, rem } from '@mantine/core';
import { IconBuilding, IconUser } from '@tabler/icons-react';
import { useState } from 'react';
import UserSettings from '@/containers/Settings/UserSettings';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import OrganisationSettings from '@/containers/Settings/OrganisationSettings';
import IsGranted from '@/components/Common/IsGranted';
import { Permission } from '@/models/Permission';

enum TabsList {
  USER = 'user',
  ORGANISATION = 'organisation',
}

export const Settings = () => {
  const [activeTab, setActiveTab] = useState<string | null>(TabsList.USER);
  const iconStyle = { width: rem(15), height: rem(15) };

  function handleTabChange(value: string | null): void {
    if (value) {
      setActiveTab(value);
    }
  }

  return (
    <div>
      <PageInfoHeader
        title="🔧 Podešavanja profila"
        subtitle="Personalizujte svoj profil i prilagodite podešavanja"
        withBorder={false}
      />

      <Tabs value={activeTab} onChange={(value) => handleTabChange(value)} my="xl">
        <Tabs.List>
          <Tabs.Tab value={TabsList.USER} leftSection={<IconUser style={iconStyle} />}>
            Profil
          </Tabs.Tab>
          <IsGranted permissions={Permission.ORGANISATION_SETTINGS_TAB_VIEW}>
            <Tabs.Tab
              value={TabsList.ORGANISATION}
              leftSection={<IconBuilding style={iconStyle} />}
            >
              Klub/Akademija
            </Tabs.Tab>
          </IsGranted>
        </Tabs.List>

        <Tabs.Panel value={TabsList.USER}>
          <UserSettings />
        </Tabs.Panel>

        <IsGranted permissions={Permission.ORGANISATION_SETTINGS_TAB_VIEW}>
          <Tabs.Panel value={TabsList.ORGANISATION}>
            <OrganisationSettings />
          </Tabs.Panel>
        </IsGranted>
      </Tabs>
    </div>
  );
};
