import { Button, Tabs, rem } from '@mantine/core';
import { IconChecklist, IconPlus, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import PaidMemberships from '@/containers/Memberships/PaidMemberships';
import useShowModal from '@/components/Modals/showModal';
import CreateMembershipPayment from '@/containers/Memberships/CreateMembershipPayment';
import UnpaidMemberships from '@/containers/Memberships/UnpaidMemberships';

enum TabsList {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export const Memberships = () => {
  const showModal = useShowModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | null>(TabsList.PAID);
  const iconStyle = { width: rem(15), height: rem(15) };

  const openModal = () =>
    showModal({
      id: 'create-membership-paymnet',
      title: '💳 Evidentiraj uplatu',
      description:
        'Unesite informacije o plaćenoj članarini. Pažljivo unesite informacije o polazniku, mesecu za uplatu i iznosu.',
      children: <CreateMembershipPayment />,
    });

  function handleTabChange(value: string | null): void {
    if (value) {
      setActiveTab(value);
      setSearchParams(`tab=${value}`);
    }
  }

  useEffect(() => {
    const tabQueryParam = searchParams.get('tab');

    if (tabQueryParam && Object.values(TabsList).includes(tabQueryParam as TabsList)) {
      setActiveTab(tabQueryParam);
    }
  }, [searchParams]);

  return (
    <div>
      <PageInfoHeader
        title="💳 Članarine"
        subtitle="Jednostavno praćenje i upravljanje članarinama"
        withBorder={false}
        actionButton={
          <Button
            size="md"
            leftSection={<IconPlus size={20} color="white" />}
            fz="sm"
            onClick={openModal}
          >
            Evidentiraj uplatu clanarine
          </Button>
        }
      />

      <Tabs value={activeTab} onChange={(value) => handleTabChange(value)} my="xl">
        <Tabs.List>
          <Tabs.Tab value={TabsList.PAID} leftSection={<IconChecklist style={iconStyle} />}>
            Placene
          </Tabs.Tab>
          <Tabs.Tab value={TabsList.UNPAID} leftSection={<IconX style={iconStyle} />}>
            Neplacene
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TabsList.PAID}>
          <PaidMemberships />
        </Tabs.Panel>

        <Tabs.Panel value={TabsList.UNPAID}>
          <UnpaidMemberships />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
