import {
  ActionIcon,
  Anchor,
  Badge,
  Card,
  Flex,
  Menu,
  NumberFormatter,
  rem,
  Stack,
  Text,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import useShowModal from '../Modals/showModal';
import { PaidMembershipsTableProps } from './PaidMembershipsTable';
import IsGranted from '../Common/IsGranted';
import { Membership } from '@/models/Membership';
import DeletePaidMembership from '@/containers/Memberships/DeletePaidMembership';
import { Permission } from '@/models/Permission';

export default function PaidMembershipsMobileTable({ data, currency }: PaidMembershipsTableProps) {
  const navigate = useNavigate();

  const showModal = useShowModal();

  const deleteMembership = (membership: Membership) =>
    showModal({
      id: 'delete-membership-payment',
      title: 'Izbrisi clanarinu',
      children: <DeletePaidMembership membership={membership} />,
    });

  const handleCardRender = () => {
    if (data.length === 0) return <Text>Nema podataka...</Text>;

    return data.map((membership) => (
      <Card key={membership.id}>
        <Stack gap={10}>
          <IsGranted permissions={Permission.MEMBERSHIP_MODIFY_VIEW}>
            <Flex justify="flex-end" bg="#f5f5f5" style={{ borderRadius: 10 }}>
              <Menu position="top" offset={10}>
                <Menu.Target>
                  <ActionIcon radius="xl" variant="subtle" color="teal">
                    <IconDotsVertical size={20} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown px={10} pr={20} py={10}>
                  <Menu.Label>Opcije</Menu.Label>
                  <Menu.Item
                    onClick={() => deleteMembership(membership)}
                    color="red"
                    leftSection={<IconTrash style={{ width: rem(20), height: rem(20) }} />}
                  >
                    Izbriši članarinu
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </IsGranted>
          <Flex justify="space-between">
            <Anchor c="#555" fw={600} size="md" underline="hover" ff="heading">
              Polaznik
            </Anchor>
            <Anchor
              c="#777"
              fw={600}
              size="md"
              underline="hover"
              ff="heading"
              onClick={() => navigate(`/members/${membership.member.id}`)}
            >
              {membership.member.full_name}
            </Anchor>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Iznos
            </Text>
            <Text c="gray.7" fw={500} size="md">
              <Badge size="lg">
                <NumberFormatter
                  suffix={` ${currency}`}
                  value={membership.amount_paid}
                  thousandSeparator
                />
              </Badge>
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Mesec
            </Text>
            <Text size="md" c="#777">
              {dayjs(membership.month).format('YYYY/MMMM')}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Naplatio
            </Text>
            <Text size="md" c="#777">
              {membership.collected_by.full_name}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Datuma
            </Text>
            <Text ta="right" size="md" c="#777">
              {dayjs(membership.created_at).format('dddd, MMMM D, YYYY')}
            </Text>
          </Flex>
        </Stack>
      </Card>
    ));
  };
  return (
    <Stack px={0} gap={10}>
      {handleCardRender()}
    </Stack>
  );
}
