export enum Permission {
  OWNERS_VIEW = 'owners.view',
  ALLOW_ALL_VIEW = 'allow_all.view',
  HOMEPAGE_VIEW = 'home_page.view',
  GROUPS_VIEW = 'groups.view',
  GROUP_MODIFY_VIEW = 'group_modify.view',
  COACHES_VIEW = 'coaches.view',
  MEMBERS_VIEW = 'members.view',
  MEMBER_DETAILS_VIEW = 'member_details.view',
  MEMBERSHIPS_VIEW = 'memberships.view',
  MEMBERSHIP_MODIFY_VIEW = 'membership_modify.view',
  TRAININGS_VIEW = 'trainings.view',
  TRAINING_DETAILS_VIEW = 'training_details.view',
  SETTINGS_VIEW = 'settings.view',
  USER_SETTINGS_TAB_VIEW = 'user_settings_tab.view',
  ORGANISATION_SETTINGS_TAB_VIEW = 'organisation_settings_tab.view',
}
