import { Card, Flex, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import TableActionsMenu from '../Common/Table/TableActionMenu';
import { Member, displayMemberGender } from '@/models/Member';
import DeleteMember from '@/containers/Members/DeleteMember';
import useShowModal from '../Modals/showModal';

export default function MemberMobileTable({ data }: { data: Member[] }) {
  const navigate = useNavigate();

  const showModal = useShowModal();

  const openDeleteModal = (memberId: string) => {
    showModal({
      id: 'delete-member',
      title: 'Izbriši polaznika',
      children: <DeleteMember memberId={memberId} />,
      size: 'md',
    });
  };

  const handleCardRender = () => {
    if (data.length === 0) return <Text>Nema podataka...</Text>;

    return data.map((member) => (
      <Card key={member.id}>
        <Stack gap={10}>
          <Flex justify="flex-end" bg="#f5f5f5" style={{ borderRadius: 10 }}>
            <TableActionsMenu optionsHandler={() => openDeleteModal(member.id)} />
          </Flex>
          <Flex justify="space-between">
            <Text size="lg" fw={600} c="#555">
              Ime
            </Text>
            <Text size="lg" fw={600} c="#777" onClick={() => navigate(`/members/${member.id}`)}>
              {member.full_name}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Godina rođenja
            </Text>
            <Text size="md" c="#777">
              {dayjs(member.birth_date).format('YYYY')}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Pol
            </Text>
            <Text size="md" c="#777">
              {displayMemberGender(member.gender)}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Grupa
            </Text>
            <Text size="md" c="#777">
              {member.group ? member.group?.name : '-'}
            </Text>
          </Flex>
        </Stack>
      </Card>
    ));
  };
  return (
    <Stack px={0} gap={10}>
      {handleCardRender()}
    </Stack>
  );
}
