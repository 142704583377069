import { Anchor, Table, ThemeIcon, Title } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Attendance } from '@/models/Training';
import useIsMobile from '@/hooks/useIsMobile';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';

export function TrainingAttendanceTable({ attendances }: { attendances: Attendance[] }) {
  const isMobile = useIsMobile();
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();

  function handleMainColumClick(memberId: string) {
    return isGranted(Permission.MEMBER_DETAILS_VIEW) && navigate(`/members/${memberId}`);
  }

  const handleRowsRender = () =>
    attendances.map((attendance, index) => (
      <Table.Tr key={index}>
        <Table.Td onClick={() => handleMainColumClick(attendance.member.id)}>
          <Anchor
            c="gray.9"
            fw={500}
            size="sm"
            underline={isGranted(Permission.MEMBER_DETAILS_VIEW) ? 'hover' : 'never'}
            ff="heading"
          >
            {attendance.member.full_name}
          </Anchor>
        </Table.Td>

        <Table.Td ta={isMobile ? 'right' : 'left'}>
          <AttendanceStatus isPresent={attendance.is_present} />
        </Table.Td>

        <Table.Td />
      </Table.Tr>
    ));

  return (
    <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover mt={30}>
      <Table.Thead style={{ width: '100%', backgroundColor: '#FAF9F8' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th style={{ width: '30%' }}>
            <Title fw={600} size={16} c="gray.6" w={150}>
              Ime i Prezime
            </Title>
          </Table.Th>

          <Table.Th>
            <Title fw={600} size={16} c="gray.6" ta={isMobile ? 'right' : 'left'}>
              Prisustvo
            </Title>
          </Table.Th>

          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
}

const AttendanceStatus = ({ isPresent }: { isPresent: boolean }) =>
  isPresent ? (
    <ThemeIcon radius="xl" size="sm" color="green">
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  ) : (
    <ThemeIcon radius="xl" size="sm" color="red">
      <IconX style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  );
