import { IconPlus } from '@tabler/icons-react';
import { Button } from '@mantine/core';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import GroupsContainer from '@/containers/Groups/GroupsContainer';
import useShowModal from '@/components/Modals/showModal';
import CreateGroup from '@/containers/Groups/CreateGroup';

export const Groups = () => {
  const showModal = useShowModal();
  const openModal = () =>
    showModal({
      id: 'create-group',
      title: 'Kreiraj grupu',
      description: 'Dodajte trenere i članove sa lakoćom',
      children: <CreateGroup />,
      closeOnClickOutside: false,
    });
  return (
    <div>
      <PageInfoHeader
        title="🏅 Grupe"
        subtitle="Organizujte grupe, dodajte trenere i članove sa lakoćom"
        actionButton={
          <Button
            onClick={openModal}
            size="md"
            leftSection={<IconPlus size={20} color="white" />}
            fz="sm"
          >
            Kreiraj grupu
          </Button>
        }
      />

      <GroupsContainer />
    </div>
  );
};
