import React from 'react';
import { Loader, Text } from '@mantine/core';
import classes from './AppLoading.module.css';

export default function AppLoading() {
  return (
    <div className={classes.container}>
      <Loader color="teal" size="xl" />
      <Text c="black" size="xl" ta="center" mt={20}>
        Empowering Sports Academies to Achieve Excellence
      </Text>
      <Text c="black" size="lg" ta="center" mt={20}>
        Managing members, training sessions, and memberships with ease
      </Text>
    </div>
  );
}
