import { Card, SimpleGrid, Text } from '@mantine/core';
import { Member, displayMemberGender } from '@/models/Member';

export default function MemberBasicInfo({
  member,
  currency,
}: {
  member: Member;
  currency?: string;
}) {
  function getMemberPreviousDebt(debt: string) {
    return Number(debt) > 0 ? `${debt} ${currency}` : '-';
  }

  const basicInfo: { label: string; value: string }[] = [
    { label: 'Datum rođenja', value: member.birth_date.toString() },
    { label: 'Broj telefona', value: member.phone_number || 'Nema podataka' },
    {
      label: 'Cena članarine',
      value: `${member.membership_price} ${currency}` || 'Nije navedeno',
    },

    { label: 'Adresa', value: member.address || 'Nema podataka' },
    { label: 'Pol', value: displayMemberGender(member.gender) },
    { label: 'Grupa', value: member.group?.name || 'Nema dodeljenu grupu' },
    { label: 'Dres', value: member.has_jersey ? 'Zadužio' : 'Nije zadužio' },
    { label: 'Trenerka', value: member.has_tracksuit ? 'Zadužio' : 'Nije zadužio' },
    { label: 'Lekarsko uverenje', value: member.has_medical_certificate ? 'Ima' : 'Nema' },
    {
      label: 'Prethodna dugovanja',
      value: getMemberPreviousDebt(member.previous_debt),
    },
  ];

  return (
    <SimpleGrid
      mt={50}
      cols={{
        base: 1,
        sm: 1,
        md: 2,
        lg: 4,
        xl: 5,
      }}
      spacing="md"
    >
      {basicInfo.map((item) => (
        <Card key={item.label} p="md">
          <Text ff="heading" fw={600} c="gray.9">
            {item.label}
          </Text>
          <Text size="sm" c="gray.8" ff="monospace" mt={10}>
            {item.value}
          </Text>
        </Card>
      ))}
    </SimpleGrid>
  );
}
