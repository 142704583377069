import { useState } from 'react';
import { Box, Card, Flex, Pagination, rem, Select } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import { useTrainings } from '@/api/hooks/trainings/useTrainings';
import TrainingsTable from '@/components/Trainings/TrainingsTable';
import { Training } from '@/models/Training';
import TrainingsFilterPopover from '@/components/Trainings/TrainingsFilterPopover';
import TrainingsMobileTable from '@/components/Trainings/TrainingsMobileTable';
import { useAccountStore } from '@/stores/accountStore';
import { Constants } from '@/constants';
import useIsMobile from '@/hooks/useIsMobile';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import PageLoading from '@/components/Common/PageLoading';
import ResourceNotFound from '@/components/Common/ResourceNotFound/ResourceNotFound';

export interface TrainingFilters {
  date: string;
  coachId: string;
}

export default function TrainingsContainer() {
  const isMobile = useIsMobile();
  const { organisation } = useAccountStore();
  const [activePage, setActivePage] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState<string | null>('');

  const [filterTrainings, setFilterTrainings] = useState<TrainingFilters>({
    coachId: '',
    date: '',
  });

  const {
    data: trainings,
    isLoading,
    isError,
  } = useTrainings({
    organisationId: organisation?.id as string,
    location: selectedLocation as string,
    date: filterTrainings.date,
    coachId: filterTrainings.coachId,
    page: activePage,
  });

  const lastPage = Math.ceil(Number(trainings?.count) / Constants.PAGE_SIZE);

  if (isLoading) return <PageLoading />;
  if (isError) return <ResourceNotFound />;

  return (
    <Box mt="xl">
      <Flex gap={10}>
        <Select
          leftSection={<IconMapPin style={{ width: rem(16), height: rem(16) }} />}
          placeholder="Odaberi lokaciju treninga"
          w={350}
          data={organisation?.locations.map((loc) => ({
            value: loc.id,
            label: loc.name,
          }))}
          value={selectedLocation}
          onChange={(val) => {
            setSelectedLocation(val);
            setActivePage(1);
          }}
          // disabled={isMembersLoading}
          clearable
        />
        <TrainingsFilterPopover
          filterTrainings={filterTrainings}
          setFilterTrainings={setFilterTrainings}
          setActivePage={setActivePage}
        />
      </Flex>

      <Card mt={10} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
        {isLoading ? (
          <TableSkeletonLoading />
        ) : !isMobile ? (
          <TrainingsTable data={trainings?.results as Training[]} />
        ) : (
          <TrainingsMobileTable data={trainings?.results as Training[]} />
        )}
      </Card>

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Box>
  );
}
