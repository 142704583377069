import { useState } from 'react';
import { Box, Card, Flex, Pagination } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMembers } from '@/api/hooks/members/useMembers';
import SearchTextField from '@/components/Common/SearchTextField';
import { useAccountStore } from '@/stores/accountStore';
import MembersTable from '@/components/Members/MembersTable';
import MembersFilterPopover from '@/components/Members/MembersFilterPopover';
import MemberMobileTable from '@/components/Members/MemberMobileTable';
import { Constants } from '@/constants';
import useIsMobile from '@/hooks/useIsMobile';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import ResourceNotFound from '@/components/Common/ResourceNotFound/ResourceNotFound';

export interface MembersFilter {
  gender: string;
  year: string;
}

const MembersContainer = () => {
  const isMobile = useIsMobile();

  const { organisation } = useAccountStore();
  const [search, setSearch] = useState('');
  const [filterMembers, setFilterMembers] = useState<MembersFilter>({
    gender: '',
    year: '',
  });
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const [activePage, setActivePage] = useState(1);

  const {
    data: members,
    isLoading,
    error,
  } = useMembers({
    organisationId: organisation?.id as string,
    name: debouncedSearch,
    gender: filterMembers.gender,
    year: filterMembers.year,
    page: activePage,
  });

  const lastPage = Math.ceil(Number(members?.count) / Constants.PAGE_SIZE);

  if (error) return <ResourceNotFound />;
  return (
    <Box>
      <Flex gap={10}>
        <SearchTextField
          placeholder="Pretraži polaznike.."
          value={search}
          onClear={() => setSearch('')}
          onChange={(event) => {
            setSearch(event.currentTarget.value);
            setActivePage(1);
          }}
        />

        <MembersFilterPopover
          filterMembers={filterMembers}
          setFilterMembers={setFilterMembers}
          setActivePage={setActivePage}
        />
      </Flex>

      <Card mt={10} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
        {isLoading ? (
          <TableSkeletonLoading />
        ) : (
          members &&
          (!isMobile ? (
            <MembersTable data={members.results} />
          ) : (
            <MemberMobileTable data={members.results} />
          ))
        )}
      </Card>

      {lastPage > 1 ? (
        <Pagination mt={20} mb={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Box>
  );
};

export default MembersContainer;
