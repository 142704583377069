import { Box, Card, Pagination, Stack, Title, Text, Flex } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { MonthPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useMemberTrainingsAttendance } from '@/api/hooks/members/useMemberTrainingsAttendance';
import TrainingAttendanceProgressBar from '@/components/Trainings/TrainingAttendanceProgressBar';
import MemberTrainingsAttendanceTable from '@/components/Members/Details/Attendance/MemberTrainingsAttendanceTable';
import MemberTrainingsAttendanceMobileTable from '@/components/Members/Details/Attendance/MemberTrainingsAttendanceMobileTable';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';

interface MemberTrainingsAttendanceProps {
  memberId?: string;
  memberJoinDate: string;
}

export default function MemberTrainingsAttendance({
  memberId,
  memberJoinDate,
}: MemberTrainingsAttendanceProps) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [activePage, setActivePage] = useState(1);

  const [period, setPeriod] = useState<Date | null>(new Date());
  const [minDate, setMinDate] = useState(new Date());

  const { data: memberAttendanceHistory, isLoading } = useMemberTrainingsAttendance({
    memberId: memberId as string,
    period: dayjs(period).format('YYYY-MM'),
    page: activePage,
  });

  const lastPage = Math.ceil(Number(memberAttendanceHistory?.count) / 100);

  useEffect(() => {
    const threeMonthsAgo = dayjs().subtract(3, 'month').startOf('month');
    const memberJoin = dayjs(memberJoinDate);

    // If member joined less than three months ago, use their join date, else use three months ago
    if (memberJoin.isAfter(threeMonthsAgo)) {
      setMinDate(memberJoin.toDate());
    } else {
      setMinDate(threeMonthsAgo.toDate());
    }
  }, [memberJoinDate]);

  return (
    <Card mt={30} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Flex justify="space-between" direction={{ base: 'column', sm: 'row' }}>
        <Stack pt={10} px={10}>
          <Title fw={600} size={20} c="gray.9">
            Prisutnost na treninzima
          </Title>

          <MonthPickerInput
            w={{ base: 300, sm: 400 }}
            label="Izaberite mesec za koji želite da vidite pristunost"
            value={period}
            onChange={setPeriod}
            minDate={minDate}
            maxDate={new Date()}
          />
        </Stack>

        {memberAttendanceHistory && (
          <Box px={10} mt={20} w={{ base: '100%', sm: 400 }}>
            <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
              prisustvo
            </Text>
            <TrainingAttendanceProgressBar
              trainingAttendances={memberAttendanceHistory.results}
              label=""
            />
          </Box>
        )}
      </Flex>

      {isLoading && <TableSkeletonLoading />}

      {memberAttendanceHistory &&
        (!isMobile ? (
          <MemberTrainingsAttendanceTable data={memberAttendanceHistory.results} />
        ) : (
          <MemberTrainingsAttendanceMobileTable data={memberAttendanceHistory.results} />
        ))}

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Card>
  );
}
