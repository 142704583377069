import { DEFAULT_THEME, createTheme } from '@mantine/core';

export const theme = createTheme({
  fontFamily: `Inter, ${DEFAULT_THEME.fontFamily}`,
  fontFamilyMonospace: 'Menlo, Monaco, monospace',
  headings: {
    fontFamily: `Inter, ${DEFAULT_THEME.fontFamily}`,
  },
  primaryColor: 'teal',
  defaultRadius: 'md',
});
