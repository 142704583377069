import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Anchor, Badge, Indicator, Table, Text, Title } from '@mantine/core';
import NoDataTable from '../Common/Table/NoDataTable';
import { Training } from '@/models/Training';
import { isWithin15Minutes } from '@/pages/Trainings/TrainingDetails.page';
import TrainingAttendanceProgressBar from './TrainingAttendanceProgressBar';

interface TrainingTableProps {
  data: Training[];
}

const TrainingsTable = ({ data }: TrainingTableProps) => {
  const navigate = useNavigate();

  const isToday = (date: Date) => dayjs(date).isSame(dayjs(), 'day');

  const handleRowsRender = () => {
    if (data.length === 0) return <NoDataTable />;

    return data.map((training) => (
      <Table.Tr key={training.id}>
        <Table.Td style={{ cursor: 'pointer' }}>
          <Anchor
            style={{ display: 'flex', alignItems: 'center', gap: 10 }}
            c="gray.9"
            fw={500}
            size="sm"
            underline="hover"
            ff="heading"
            onClick={() => navigate(`/trainings/${training.id}`)}
          >
            {isWithin15Minutes(training.date) ? (
              <Indicator inline processing color="teal" mr={5} />
            ) : null}

            {dayjs(training.date).format('dddd - DD/MM/YYYY - HH:mm')}

            {isToday(training.date) ? (
              <Badge variant="light" color="indigo" size="sm">
                Danas
              </Badge>
            ) : null}
          </Anchor>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {training.group.name}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {training.coaches.map((el) => el.full_name).join(', ')}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {training.location?.name || '-'}
          </Text>
        </Table.Td>
        <Table.Td style={{ textAlign: 'center' }}>
          <TrainingAttendanceProgressBar trainingAttendances={training.attendances} />
        </Table.Td>
        <Table.Td />
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead style={{ width: '100%', backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Trening
            </Title>
          </Table.Th>

          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Grupa
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Trener
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Lokacija
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Prisustvo
            </Title>
          </Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
};

export default TrainingsTable;
