import { Card, Flex, NumberFormatter, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { Membership } from '@/models/Membership';
import { PaidStatus } from './MemberMembershipsTable';

export default function MemberMembershipsMobileTable({
  memberships,
  currency,
}: {
  memberships: Membership[];
  currency: string;
}) {
  const handleCardRender = () => {
    if (memberships.length === 0) return <Text>Nema podataka...</Text>;

    return memberships.map((membership) => (
      <Card key={membership.id}>
        <Stack gap={10}>
          <Flex justify="space-between">
            <Text size="lg" fw={600} c="#555">
              Mesec
            </Text>
            <Text size="lg" fw={600} c="#777">
              {dayjs(membership.month).format('YYYY/MMMM')}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Plaćeno
            </Text>
            <PaidStatus isPaid={membership.is_paid} shouldPay={membership.should_pay} />
          </Flex>
          {membership.is_paid ? (
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Iznos
              </Text>
              <Text size="md" c="#777">
                <NumberFormatter
                  suffix={` ${currency}`}
                  value={membership.amount_paid}
                  thousandSeparator
                />
              </Text>
            </Flex>
          ) : null}

          {membership.collected_by ? (
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Naplatio
              </Text>
              <Text size="md" c="#777">
                {membership.collected_by.full_name}
              </Text>
            </Flex>
          ) : null}

          {membership.payment_date ? (
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Datuma
              </Text>
              <Text size="md" c="#777">
                {dayjs(membership.payment_date).format('dddd, MMMM D, YYYY')}
              </Text>
            </Flex>
          ) : null}
        </Stack>
      </Card>
    ));
  };
  return (
    <Stack px={0} gap={10} mt={20}>
      {handleCardRender()}
    </Stack>
  );
}
