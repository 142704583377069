import { Anchor, Badge, Card, Flex, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Attendance } from '@/models/Training';
import { PresenceStatus } from './MemberTrainingsAttendanceTable';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';

export default function MemberTrainingsAttendanceMobileTable({ data }: { data: Attendance[] }) {
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();

  const isToday = (date: Date) => dayjs(date).isSame(dayjs(), 'day');
  const canNavigate = isGranted(Permission.TRAINING_DETAILS_VIEW);

  const handleCardRender = () => {
    if (data.length === 0) return <Text>Nema podataka...</Text>;

    return data.map((attendance) => (
      <Card key={attendance.id}>
        <Stack gap={10}>
          <Anchor
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              cursor: canNavigate ? 'pointer' : 'default',
            }}
            c="gray.9"
            fw={500}
            size="md"
            underline={canNavigate ? 'hover' : 'never'}
            ff="heading"
            onClick={() => canNavigate && navigate(`/trainings/${attendance.training_session.id}`)}
          >
            {dayjs(attendance.training_session.date).format('dddd - DD/MM/YYYY - HH:mm')}

            {isToday(attendance.training_session.date) ? (
              <Badge variant="light" color="indigo" size="xs">
                Danas
              </Badge>
            ) : null}
          </Anchor>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Prisustvo
            </Text>
            <PresenceStatus isPresent={attendance.is_present} />
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Grupa
            </Text>
            <Text size="md" c="#777">
              {attendance.training_session.group?.name}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Trener
            </Text>
            <Text size="md" c="#777">
              {attendance.training_session.coaches.map((el) => el.full_name).join(', ')}
            </Text>
          </Flex>

          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Lokacija
            </Text>
            <Text size="md" c="#777">
              {attendance.training_session.location?.name || '-'}
            </Text>
          </Flex>
        </Stack>
      </Card>
    ));
  };
  return (
    <Stack px={0} gap={10} mt={20}>
      {handleCardRender()}
    </Stack>
  );
}
