import PageInfoHeader from '@/components/Common/PageInfoHeader';
import TrainingsContainer from '@/containers/Trainings/TrainingsContainer';

export const Trainings = () => (
  <div>
    <PageInfoHeader
      title="🏋️‍♂️ Treninzi"
      subtitle="Detaljno planiranje i vođenje svih trening sesija"
    />
    <TrainingsContainer />
  </div>
);
