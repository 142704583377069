import { Box, Card, Flex, Pagination, Select, rem } from '@mantine/core';
import { useState } from 'react';
import { IconUser } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useAccountStore } from '@/stores/accountStore';
import PaidMembershipsTable from '@/components/Memberships/PaidMembershipsTable';
import { useMemberships } from '@/api/hooks/memberships/useMemberships';
import { getUserFullName } from '@/models/User';
import PaidMembershipsPopoverDateFilters from '@/components/Memberships/PaidMembershipsPopoverDateFilters';
import { getMonthFromDate, getYearFromDate } from '@/utils/dateUtils';
import PaidMembershipsMobileTable from '@/components/Memberships/PaidMembershipsMobileTable';
import { Constants } from '@/constants';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';

export interface MembershipFilters {
  membershipMonth: string;
  paymentDate: string;
}

export default function PaidMemberships() {
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const { organisation } = useAccountStore();
  const [activePage, setActivePage] = useState(1);
  const [selectedCoach, setSelectedCoach] = useState<string | null>('');
  const [membershipFilters, setMembershipFilters] = useState<MembershipFilters>({
    membershipMonth: '',
    paymentDate: '',
  });

  const { data: coaches, isLoading: isMembersLoading } = useCoaches(organisation?.id as string);

  const {
    data: memberships,
    isLoading,
    error,
  } = useMemberships({
    organisationId: organisation!.id,
    coach: selectedCoach as string,
    month: getMonthFromDate(membershipFilters.membershipMonth),
    year: getYearFromDate(membershipFilters.membershipMonth),
    paymentDate: membershipFilters.paymentDate,
    page: activePage,
  });

  const lastPage = Math.ceil(Number(memberships?.count) / Constants.PAGE_SIZE);

  if (error) return <div>Something went wrong..</div>;

  return (
    <Box mt="xl">
      <Flex gap={10}>
        <Select
          leftSection={<IconUser style={{ width: rem(16), height: rem(16) }} />}
          placeholder="Odaberi trenera"
          w={350}
          data={coaches?.results.map((coach) => ({
            value: coach.user.id,
            label: getUserFullName(coach.user),
          }))}
          value={selectedCoach}
          onChange={(val) => {
            setSelectedCoach(val);
            setActivePage(1);
          }}
          disabled={isMembersLoading}
          clearable
        />

        <PaidMembershipsPopoverDateFilters
          membershipFilters={membershipFilters}
          setMembershipFilters={setMembershipFilters}
          setActivePage={setActivePage}
        />
      </Flex>

      <Card mt={20} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
        {isLoading ? (
          <TableSkeletonLoading />
        ) : !isMobile ? (
          <PaidMembershipsTable data={memberships!.results} currency={organisation!.currency} />
        ) : (
          <PaidMembershipsMobileTable
            data={memberships!.results}
            currency={organisation!.currency}
          />
        )}
      </Card>

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Box>
  );
}
