import { useParams, useSearchParams } from 'react-router-dom';
import { IconCalendarClock, IconCoins, IconUserPentagon } from '@tabler/icons-react';
import { rem, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMemberDetails } from '@/api/hooks/members/useMemberDetails';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import { Member } from '@/models/Member';
import PageLoading from '@/components/Common/PageLoading';
import MemberBasicInfo from '@/components/Members/Details/MemberBasicInfo';
import MemberMemberships from '@/containers/Members/Details/MemberMemberships';
import MemberTrainingsAttendance from '@/containers/Members/Details/MemberTrainingsAttendance';
import useShowModal from '@/components/Modals/showModal';
import MemberActionButtonMenu from '@/components/Members/Details/MemberActionButtonMenu';
import DeleteMember from '@/containers/Members/DeleteMember';
import EditMember from '@/containers/Members/EditMember';
import MemberAdditonalOptionsForm from '@/containers/Members/Details/MemberAdditonalOptionsForm';
import ResourceNotFound from '@/components/Common/ResourceNotFound/ResourceNotFound';
import { useAccountStore } from '@/stores/accountStore';
import MemberParents from '@/containers/Members/Details/Parents/MemberParents';

enum TabsList {
  FINANCIALS = 'financials',
  ATTENDANCE = 'attendance',
  PARENTS = 'parents',
}

export const MemberDetails = () => {
  const { organisation } = useAccountStore();
  const { id } = useParams();
  const { data: member, isLoading, isError } = useMemberDetails({ memberId: id as string });
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | null>(TabsList.FINANCIALS);

  const showModal = useShowModal();

  const iconStyle = { width: rem(15), height: rem(15) };

  function openEditMemberModal() {
    showModal({
      id: 'edit-member',
      title: 'Izmeni polaznika',
      children: <EditMember member={member as Member} />,
    });
  }

  function openMemberAdditionalInfoModal() {
    showModal({
      id: 'additional-info',
      title: 'Dodatne opcije',
      children: member ? <MemberAdditonalOptionsForm member={member} /> : null,
      size: 'md',
    });
  }

  function openMemberDeleteModal() {
    showModal({
      id: 'delete-member',
      title: 'Izbriši polaznika',
      children: member ? <DeleteMember memberId={member?.id} /> : null,
      size: 'md',
    });
  }

  function handleTabChange(value: string | null): void {
    if (value) {
      setActiveTab(value);
      setSearchParams(`tab=${value}`);
    }
  }

  useEffect(() => {
    const tabQueryParam = searchParams.get('tab');

    if (tabQueryParam && Object.values(TabsList).includes(tabQueryParam as TabsList)) {
      setActiveTab(tabQueryParam);
    }
  }, [searchParams]);

  if (isLoading) return <PageLoading />;
  if (isError) return <ResourceNotFound />;

  return (
    <div>
      <PageInfoHeader
        title={`👤 ${member?.full_name}`}
        subtitle="Profil polaznika"
        actionButton={
          <MemberActionButtonMenu
            onMemberEdit={openEditMemberModal}
            onMemberDelete={openMemberDeleteModal}
            onAdditionalOptions={openMemberAdditionalInfoModal}
          />
        }
      />

      {member ? (
        <>
          <MemberBasicInfo member={member} currency={organisation?.currency} />

          <Tabs
            keepMounted={false}
            value={activeTab}
            onChange={(value) => handleTabChange(value)}
            my="xl"
            variant="outline"
          >
            <Tabs.List>
              <Tabs.Tab value={TabsList.FINANCIALS} leftSection={<IconCoins style={iconStyle} />}>
                Finansije
              </Tabs.Tab>
              <Tabs.Tab
                value={TabsList.ATTENDANCE}
                leftSection={<IconCalendarClock style={iconStyle} />}
              >
                Prisutnost
              </Tabs.Tab>

              <Tabs.Tab
                value={TabsList.PARENTS}
                leftSection={<IconUserPentagon style={iconStyle} />}
              >
                Roditelji
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={TabsList.FINANCIALS}>
              <MemberMemberships member={member} currency={organisation!.currency} />
            </Tabs.Panel>

            <Tabs.Panel value={TabsList.ATTENDANCE}>
              <MemberTrainingsAttendance memberId={member.id} memberJoinDate={member.join_date} />
            </Tabs.Panel>

            <Tabs.Panel value={TabsList.PARENTS}>
              <MemberParents member={member} />
            </Tabs.Panel>
          </Tabs>
        </>
      ) : null}
    </div>
  );
};
