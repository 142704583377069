import { Box, Progress, Text } from '@mantine/core';
import React from 'react';
import { Attendance } from '@/models/Training';

export default function TrainingAttendanceProgressBar({
  trainingAttendances,
  label = 'prisutno',
}: {
  trainingAttendances: Attendance[];
  label?: string;
}) {
  const totalParticipants = trainingAttendances.length;
  const attendeesCount = trainingAttendances.filter((participant) => participant.is_present).length;
  const attendeesPercentage = (attendeesCount / totalParticipants) * 100;

  const progressBarColor = () => {
    switch (true) {
      case attendeesPercentage < 25:
        return 'red';
      case attendeesPercentage < 50:
        return 'orange';
      case attendeesPercentage < 75:
        return 'yellow';
      default:
        return 'teal';
    }
  };

  return (
    <Box>
      <Progress value={attendeesPercentage} color={progressBarColor()} />
      <Text ta="center" size="xs" style={{ color: 'GrayText' }} mt={5}>
        {attendeesCount} / {totalParticipants} {label}
      </Text>
    </Box>
  );
}
